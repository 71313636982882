import '/assets/styles/sections/home-topnav.scss';
import config from '/config';

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Placeholder from '/views/partials/placeholder';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

const HomeTopNav = ({ data }) => {
	return (
    <nav className="section home-topnav">
      <div className="wrapper topnav-wrap">
        {config.customLists?.internetProducts?.linkParams &&
          <Link
            {...config.customLists?.internetProducts.linkParams}
            className="topnav-link">
            <Icon name="angle-left" />
            <span>İnternete Özel</span>
          </Link>
        }
        {config.customLists?.outletProducts?.linkParams &&
          <Link
            {...config.customLists?.outletProducts.linkParams}
            className="topnav-link">
            <span>Outlet</span>
            <Icon name="angle-right" />
          </Link>
        }
      </div>
    </nav>
  )
}

export default HomeTopNav